<template>
    <div>
        <b-loading :is-full-page="true" v-model="isLoading"/>

        <b-steps size="is-large" v-show="!isLoading">
            <b-step-item label="Banque" icon="euro-sign">

                <h2 class="w-100 text-center mt-4">Informations bancaires</h2>

                <div class="row">
                    <div class="col-md-12 col-xl-6 m-auto">
                        <div class="d-flex is-justify-content-center pt-4 flex-lg-row flex-xl-row ">

                            <div class="box w-100">
                                <b-field label="Montant à retirer">
                                    <b-input required v-model="benefits" disabled/>
                                </b-field>


                                <div class="d-flex flex-column box has-background-info-light my-4">
                                    <b-field label="Nom de la banque">
                                        <b-input required v-model="withdrawal.user.banque"/>
                                    </b-field>

                                    <div class="row">
                                        <b-field label="IBAN" class="col-md-12 col-lg-8">
                                            <b-input required v-model="withdrawal.user.iban"/>
                                        </b-field>
                                        <b-field label="BIC" class="col-md-12 col-lg-4">
                                            <b-input required v-model="withdrawal.user.bic"/>
                                        </b-field>
                                    </div>
                                </div>

                                <b-field class="ml-1">
                                    <b-checkbox> Sauvegarder mes informations comme moyen de paiement par défaut
                                    </b-checkbox>
                                </b-field>

                            </div>
                        </div>
                    </div>
                </div>

            </b-step-item>


            <b-step-item label="Facturation" icon="user">

                <h2 class="w-100 text-center mt-4">Informations de facturation</h2>

                <div class="row">
                    <div class="col-md-12 col-xl-3">
                        <div class="d-flex is-justify-content-center pt-4 flex-lg-row flex-xl-row ">

                            <div class="box w-100">
                                <b>Banque</b><br/>
                                {{ withdrawal.user.banque }} <br/>
                                {{ withdrawal.user.iban }} - {{ withdrawal.user.bic }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xl-8 ">
                        <div class="d-flex is-justify-content-center pt-4 flex-lg-row flex-xl-row ">

                            <div class="box w-100">

                                <div class="row">
                                    <div class="col-md-12 col-xl-6">
                                        <div class="d-flex flex-column box has-background-primary-light my-4 ">
                                            <h3>Contact</h3>

                                            <div class="row">
                                                <div class="col-md-12 col-lg-6">
                                                    <b-field label="Prénom" class="mr-2">
                                                        <b-input required v-model="withdrawal.user.firstname"/>
                                                    </b-field>
                                                </div>
                                                <div class="col-md-12 col-lg-6 mb-4">
                                                    <b-field label="Nom">
                                                        <b-input required v-model="withdrawal.user.lastname"/>
                                                    </b-field>
                                                </div>

                                                <div class="col-md-12 mb-2">
                                                    <b-field label="Adresse">
                                                        <b-input required v-model="withdrawal.user.address"/>
                                                    </b-field>
                                                </div>

                                                <div class="col-md-12 col-lg-4">
                                                    <b-field label="Code postal">
                                                        <b-input required v-model="withdrawal.user.cp"/>
                                                    </b-field>
                                                </div>

                                                <div class="col-md-12 col-lg-8">
                                                    <b-field label="Ville">
                                                        <b-input required v-model="withdrawal.user.ville"/>
                                                    </b-field>
                                                </div>

                                                <div class="col-md-12 mt-2">
                                                    <nl-select
                                                        v-model="withdrawal.user.country"
                                                        class-label="form-control-label-profil"
                                                        :name="$t('user.country')"
                                                        :items="countries"
                                                        :label="$t('user.country')"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xl-6">
                                        <div class="d-flex flex-column box has-background-info-light my-4">
                                            <h3>Ma société</h3>

                                            <div class="row">
                                                <div class="col-md-12 mb-3">
                                                    <b-field label="Société">
                                                        <b-input required v-model="withdrawal.user.company"/>
                                                    </b-field>
                                                </div>

                                                <div class="col-md-12 mb-3">
                                                    <b-field label="SIRET">
                                                        <b-input :required="!withdrawal.userDoesntHaveSiret"
                                                                 v-model="withdrawal.user.siret"/>
                                                    </b-field>

                                                    <b-field class="ml-1">
                                                        <b-checkbox v-model="withdrawal.userDoesntHaveSiret">
                                                            Je n'ai pas de numéro de SIRET
                                                        </b-checkbox>
                                                    </b-field>
                                                </div>

                                                <div class="col-md-12 mb-3">
                                                    <b-field label="TVA" class="">
                                                        <b-input :required="!withdrawal.userDoesntHaveTva"
                                                                 v-model="withdrawal.user.tva_number"/>
                                                    </b-field>

                                                    <b-field class="ml-1">
                                                        <b-checkbox v-model="withdrawal.userDoesntHaveTva">
                                                            Éxonéré de TVA
                                                        </b-checkbox>
                                                    </b-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </b-step-item>


            <b-step-item label="Validation" icon="receipt">
                <h2 class="w-100 text-center mt-4">Ma facture</h2>

                <div class="row">
                    <div class="col-md-12 col-xl-6 m-auto">
                        <div class="d-flex is-justify-content-center pt-4 flex-lg-row flex-xl-row ">

                            <div class="box w-100">

                                <b-field label="Numéro de facture souhaité">
                                    <b-input required v-model="withdrawal.user_invoice_number"/>
                                </b-field>

                                <div class="m-auto text-center pt-5">
                                    <b-button type="is-warning" @click.prevent="doWithdraw">Je génère ma facture
                                    </b-button>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </b-step-item>


            <template
                #navigation="{previous, next}">
                <div class="d-flex justify-content-center">
                    <b-button
                        icon-pack="fas"
                        icon-left="caret-left"
                        :disabled="previous.disabled"
                        @click.prevent="previous.action"
                        class="mr-2"
                    >
                    </b-button>
                    <b-button
                        icon-pack="fas"
                        icon-right="caret-right"
                        :disabled="next.disabled"
                        @click.prevent="next.action">
                    </b-button>
                </div>
            </template>


        </b-steps>
    </div>
</template>

<script>
import countryList from '@/utils/countries'
import {mapActions, mapState} from 'vuex'
import router from "@/router";

export default {
    name: 'PartnerGains',
    title: 'partenaire-gains.title',
    components: {},
    data() {
        return {
            file: {},
            dropFiles: [],
            invoiceChoice: 1,
            countries: countryList['fr'],
            isLoading: false
        }
    },
    async created() {
        await this.$store.commit('billing/setWithdrawal', {
            saveDefaultPayment: null,
            user: {
                firstname: this.user.firstname,
                lastname: this.user.firstname,
                address: this.user.address,
                cp: this.user.cp,
                ville: this.user.ville,
                country: this.user.country,
                company: this.user.company,
                siret: this.user.siret,
                tva_number: this.user.tva_number,
                banque: this.user.banque,
                iban: this.user.iban,
                bic: this.user.bic,
            },
            user_invoice_number: null,
            userDoesntHaveSiret: false,
            userDoesntHaveTva: false,
        })
    },
    computed: {
        ...mapState('auth', {
            benefits: state => Number.parseFloat(state.user?.gains),
            user: state => state.user
        }),
        withdrawal: {
            get() {
                return this.$store.state.billing.withdrawal
            },
            set(value) {
                this.$store.commit('billing/setWithdrawal', value)
            }
        }
    },
    methods: {
        ...mapActions('billing', ['withdraw']),
        doWithdraw() {
            for (let property in this.withdrawal.user) {
                if(['tva_number', 'siret'].includes(property)) continue

                if (this.withdrawal.user[property] === null || this.withdrawal.user[property] === '') {
                    this.$store.dispatch('toast', {
                        type: 'error',
                        message: 'Veuillez remplir tous les champs ('+property+')'
                    });
                    return;
                }
            }

            this.isLoading = true

            this.withdraw().then(() => {
                router.push('/partner/withdraw/complete')
            }).catch((e) => {
                this.$store.dispatch('toastError', e)
            }).finally(() => {
                this.isLoading = false
            })

        }
    }
}
</script>

<style scoped>
/deep/ .b-numberinput input {
    text-align: left !important;
}

.border-xl {
    border-radius: 30px;
}
</style>